import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SocialShare from "./social-share";

const BlogBanner = ({ title, date, postFields, postUrl }) => {
	return (
		<div className="position-relative my-5 my-md-8">
			<Container>
				<Row className="align-items-md-center justify-content-center">
					<Col xl={7} className="mb-4 mb-lg-0 text-start">
						{postFields.smallHeading && (
							<h5 className="text-green fs-5 lh-base mb-2">
								{postFields.smallHeading}
							</h5>
						)}
						<h3 className="my-auto">{title}</h3>
						<div className="d-flex align-items-center mt-3 pt-1">
							<span className="small-text mb-0 montserrat"> {date}</span>
							{postFields.minToRead && (
								<>
									<span
										className="px-2 text-green"
										style={{ marginTop: "-6px" }}
									>
										.
									</span>
									<span className="montserrat mb-0">
										{postFields.minToRead} min read
									</span>
								</>
							)}
						</div>
						<Row className="mt-5 align-items-md-center">
							<Col md={8}>
								<div className="d-flex align-items-center">
									{postFields.authorTeamMember[0]?.teamMemberFields
										.featuredImage.localFile ? (
										<GatsbyImage
											image={
												postFields.authorTeamMember[0]?.teamMemberFields
													.featuredImage.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={
												postFields.authorTeamMember[0]?.teamMemberFields
													.featuredImage.altText
											}
											className="rounded-5"
											style={{ width: "56px", height: "56px" }}
										/>
									) : (
										<div
											className="bg-light-black rounded-5 d-flex align-items-center justify-content-center"
											style={{ width: "48px", height: "48px" }}
										>
											<StaticImage
												src={"../images/placeholder.png"}
												alt="Sprout Tuition"
												height={44}
												layout="fixed"
											/>
										</div>
									)}

									<div className="ms-3">
										<p className="text-schema-bg mb-0 montserrat-medium">
											{postFields.authorTeamMember[0].teamMemberFields.name}
										</p>
										<p className="text-schema-bg mb-0 montserrat">
											{
												postFields.authorTeamMember[0].teamMemberFields
													.titlerole
											}
										</p>
									</div>
								</div>
							</Col>
							<Col md={4} className="text-md-end mt-4 mt-md-0">
								<SocialShare postTitle={title} postUrl={postUrl} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col className="pt-3 pt-md-5 pt-xl-7">
						<GatsbyImage
							image={
								postFields.featuredImage?.localFile.childImageSharp
									.gatsbyImageData
							}
							style={{ borderRadius: "12px" }}
							alt={postFields.featuredImage?.altText}
							className="border-2 border-white border  banner-image"
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BlogBanner;
