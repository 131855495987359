import React, { useState } from "react";
import { FaFacebook, FaLinkedin, FaLink } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { Toast, ToastContainer } from "react-bootstrap";

const SocialShare = ({ postUrl, postTitle }) => {
  const [toast, setToast] = useState({ show: false, message: "" });

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    postUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    postUrl
  )}&text=${encodeURIComponent(postTitle)}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    postUrl
  )}`;

  const onLinkButtonClick = () => {
    document.addEventListener(
      "copy",
      function (e) {
        e.clipboardData.setData("text/plain", postUrl);
        e.preventDefault();
      },
      true
    );

    document.execCommand("copy");
    setToast({
      show: true,
      message: "Link Copied",
    });
  };

  return (
    <>
      <ul className="p-0 m-0 d-flex list-unstyled justify-content-md-end">
        <li className="me-3 bg-light-grey border-0 rounded-5">
          <button
            className="share-link p-2 d-flex bg-light-grey border-0 rounded-5"
            onClick={onLinkButtonClick}
          >
            <FaLink />
          </button>
        </li>
        <li className="me-3 p-2 border-0 rounded-5 share-link bg-light-grey">
          <a
            href={linkedInShareUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li className="me-3 p-2 share-link border-0 rounded-5 bg-light-grey">
          <a
            href={twitterShareUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiTwitterXLine  />
          </a>
        </li>
        <li className="d-inline-block p-2 share-link border-0 rounded-5 bg-light-grey">
          <a
            href={facebookShareUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
        </li>
      </ul>
      <ToastContainer
        style={{ position: "fixed", top: 0, right: 0, zIndex: 1050 }}
        position="top-end"
        className="p-3"
      >
        <Toast
          onClose={() => setToast({ ...toast, show: false })}
          show={toast.show}
          delay={3000}
          autohide
          bg={toast.bg}
        >
          <Toast.Body className="text-white bg-secondary text-start axiforma-bold">
            {toast.message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SocialShare;
